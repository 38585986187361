import { Pivot, PivotItem, PrimaryButton } from "@fluentui/react";
import { AxiosResponse } from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import { connect } from "react-redux";
import { withRouter } from "react-router";
import AcademyLeaderboard from "src/academy/AcademyLeaderboard/AcademyLeaderboard";
import AcademyCourseList from "src/academy/courses/AcademyCourseList/AcademyCourseList";
import { IAcademyCourse } from "src/academy/types";
import { localize } from "src/l10n";
import api from "src/spintr/SpintrApi";
import { Breadcrumbs, Label, PageHeader } from "src/ui";
import SpintrLoader from "src/ui/components/Loader";

interface IProps {
    appEnabled?: boolean;
    academyAppName?: string;
    history?: any;
    isAdmin?: boolean;
    isEditor?: boolean;
}

interface IState {
    isLoadingCourses: boolean;
    isLoadingLeaderboard: boolean;
    activeCourses: IAcademyCourse[];
    availableCourses: IAcademyCourse[];
    completedCourses: IAcademyCourse[];
}

const AcademyView = (props: IProps) => {
    const [state, setState] = useState<IState>({
        isLoadingCourses: true,
        isLoadingLeaderboard: true,
        activeCourses: [],
        availableCourses: [],
        completedCourses: []
    });

    useEffect(() => {
        if (!props.appEnabled) {
            return props.history.push("/404");
        }

        fetchCourses();
    }, []);

    const fetchCourses = useCallback(() => {
        api.get("/api/v1/academy/courses").then((response: AxiosResponse) => {
            setState((s: IState) => ({
                ...s,
                activeCourses: response.data.filter((x: IAcademyCourse) => x.userProgress.isInitialized && !x.userProgress.isCompleted),
                availableCourses: response.data.filter((x: IAcademyCourse) => !x.userProgress.isInitialized && !x.userProgress.isCompleted),
                completedCourses: response.data.filter((x: IAcademyCourse) => x.userProgress.isCompleted),
                isLoadingCourses: false
            }));
        }).catch(() => { });
    }, []);

    return (
        <div className="AcademyView">
            <Helmet>
                <title>{localize(props.academyAppName, true)}</title>
            </Helmet>
            <PageHeader
                title={localize(props.academyAppName, true)}
                buttons={[
                    ...(props.isAdmin || props.isEditor
                        ? [
                              {
                                  key: "create-course",
                                  title: localize("CREATE_COURSE"),
                                  text: localize("CREATE_COURSE"),
                                  icon: "add",
                                  onClick: () => {
                                      props.history.push("/admin/academy/create");
                                  },
                                  theme: "primary" as const,
                              },
                          ]
                        : []),
                ]}
            />
            {state.isLoadingCourses && <SpintrLoader />}
            {!state.isLoadingCourses && (
                <Pivot overflowBehavior={"menu"}>
                    <PivotItem headerText={localize("AVAILABLE_PLURAL")}>
                        {state.activeCourses.length > 0 && (
                            <AcademyCourseList
                                courses={state.activeCourses}
                                title={localize("IN_PROGRESS_PLURAL")}
                                big
                            />
                        )}
                        {(state.availableCourses.length > 0 || props.isAdmin || props.isEditor) && (
                            <AcademyCourseList
                                courses={state.availableCourses}
                                title={localize("NOT_STARTED_PLURAL")}
                                isFirstCourse={state.activeCourses.length + state.availableCourses.length === 0}
                                canCreateCourses={props.isAdmin || props.isEditor}
                            />
                        )}
                        {state.activeCourses.length === 0 &&
                            state.availableCourses.length === 0 &&
                            !props.isAdmin &&
                            !props.isEditor && <Label>{localize("NO_COURSES_AVAILABLE")}</Label>
                        }
                    </PivotItem>
                    {state.completedCourses.length > 0 && (
                        <PivotItem headerText={localize("COMPLETED_PLURAL")}>
                            <AcademyCourseList courses={state.completedCourses} />
                        </PivotItem>
                    )}
                    <PivotItem headerText={localize("LEADERBOARD")}>
                        <AcademyLeaderboard />
                    </PivotItem>
                </Pivot>
            )}
        </div>
    )
}

const mapStateToProps = (state: Spintr.AppState, props) => ({
    ...props,
    appEnabled: state.app.items.some((app) => app.enabled && app.id === 32),
    academyAppName: state.instance.get("academyAppName"),
    isAdmin: state.profile.active.isAdmin,
    isEditor: state.profile.active.isEditor,
});

export default withRouter(connect(mapStateToProps)(AcademyView));
